import { useContext,useState,memo } from "react";
import {createBrowserRouter,Outlet,RouterProvider} from "react-router-dom";
import {FOLDER_MEDIA_PATH} from './utils.js';
import { AuthContext } from "./context/authContext.js";
import './sass/main.scss';
import Footer from "./components/Footer.jsx";
import Brochure from "./pages/Brochure.jsx";
import Catalogue from "./pages/Catalogue.jsx";
import Guarantee from "./pages/Guarantee.jsx";
import Home from "./pages/Home.jsx";
import LegalInfo from "./pages/LegalInfo.jsx";
import News from "./pages/News.jsx";
import NewsDetail from "./pages/NewsDetail.jsx";
import PrivacyPolicy from "./pages/PrivacyPolicy.jsx";
import Product from "./pages/Product.jsx";
import ProductDetail from "./pages/ProductDetail.jsx";
import AddressList from "./pages/AddressList.jsx";
import Checkout from "./pages/Checkout.jsx";
import Contact from "./pages/Contact.jsx";
import ChangeProfile from "./pages/ChangeProfile.jsx";
import ForgotPassword from "./pages/ForgotPassword.jsx";
import About from "./pages/About.jsx";
import UserProfile from "./pages/UserProfile.jsx";
import HeaderMainContainer from "./components/HeaderMainContainer.jsx";
import NotFound from "./pages/NotFound.jsx";
import OrderStatus from "./pages/OrderStatus.jsx";
import Otp from "./pages/Otp.jsx";
import NewsArchive from "./pages/NewsArchive.jsx";
import NewsCategory from "./pages/NewsCategory.jsx";
function App () {
  const {currentUser} = useContext(AuthContext);
  const [tempMenu,setTempMenu] = useState(null);
  const [loginClicked,setLoginClicked] = useState(false);
  let activeLink = '';
  const onUpdateActiveLink = (value) => {
    // alert('masuksini',value)
    activeLink = value;
  };
  const Layout = () => {
    return (
      <div className="body-home">
          <ul className="transition">
              <li></li>
              <li></li>
              <li></li>
          </ul>
          <div className="wrapper">
              <main>
                  <div className="main-container">
                      <HeaderMainContainer activeLinkData={activeLink} onUpdate={onUpdateActiveLink} loginClicked={loginClicked} setLoginClicked={setLoginClicked} />
                      <a href="https://wa.me/+6281285389369?text=I'm%20interested%20in%20your%20car%20for%20sale" target="_blank" className="hotline">
                          <img alt="whatsappImage" src={FOLDER_MEDIA_PATH+"whatsapp.png"}/>
                      </a>
                      <Outlet/>
                  </div>
                  <Footer/>
              </main>
          </div>
      </div>
    );
  };

  const MemoizedLayout = memo(Layout);

  const router = createBrowserRouter([
    {
      path:'/',
      element:<MemoizedLayout tempMenu={tempMenu} />,
      children:[
        {
          path:'/',
          element:<Home/>
        },
        {
          path:'/produk',
          element:<Product setLoginClicked={setLoginClicked}/>
        },
        {
          path:'/detail-produk/:name',
          element:<ProductDetail setLoginClicked={setLoginClicked}/>
        },
        {
          path:'/berita',
          element:<News/>
        },
        {
          path:'/berita/arsip/:date',
          element:<NewsArchive/>
        },
        {
          path:'/berita/kategori/:kategori',
          element:<NewsCategory/>
        },
        {
          path:'/detail-berita/:id',
          element:<NewsDetail/>
        },
        {
          path:'/garansi',
          element:<Guarantee/>
        },
        {
          path:'/brosur',
          element:<Brochure/>
        },
        {
          path:'/katalog',
          element:<Catalogue/>
        },
        {
          path:'/legal-info',
          element:<LegalInfo/>
        },
        {
          path:'/privacy-policy',
          element:<PrivacyPolicy/>
        },
        {
          path:'/tentang-kami',
          element:<About/>
        },
        {
          path:'/kontak',
          element:<Contact/>
        },
        {
          path:'/pembayaran/:id',
          element:<Checkout setLoginClicked={setLoginClicked}/>
        },
        {
          path:'/daftar-alamat',
          element:<AddressList/>
        },
        {
          path:'/ubah-profil',
          element:<ChangeProfile setLoginClicked={setLoginClicked}/>
        },
        {
          path:'/profil',
          element:<UserProfile setLoginClicked={setLoginClicked}/>
        },
        {
          path:'/status-pesanan',
          element:<OrderStatus/>
        }
      ]
    },
    {
      path:'/lupa-password',
      element:<ForgotPassword/>
    },
    {
      path:'/verifikasi-otp',
      element:<Otp/>
    },
    {
      path:'*',
      element:<NotFound/>
    }
  ]);

  return (
    <div>
      <RouterProvider router={router}/>
    </div>
  );
}

export default App;